import React from "react"

import SEO from "../components/seo"
import Header from "../components/header"
import Section from "../components/atoms/Section"
import HomeSection from "../components/molecules/HomeSection"
import AboutMeSection from "../components/molecules/AboutMeSection"
import ContactSection from "../components/molecules/ContactSection"

import Query from "../Query"

import "../styles/index.scss"

const IndexPage = () => {
  const data = Query()

  return (
    <div id="home">
      <SEO
        title="Home"
        keywords="React, Node, Gatsby, Front-end, developer, API"
      />
      <Header siteTitle={"🚀"} data={data} />
      <div className="container">
        <Section alignment="align-page-center">
          <HomeSection data={data}/>
        </Section>
        <Section id="about-me" alignment="align-page-left-center">
          <AboutMeSection />
        </Section>
        <Section id="contact" alignment="align-contact-section">
          <ContactSection />
        </Section>
      </div>
    </div>
  )
}

export default IndexPage
