import React, { useState } from 'react'

const ContactSection = () => {
    const [status, setStatus] = useState("")

    const submitForm = (ev) => {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        console.log('form: ', form)
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                setStatus("SUCCESS");
            } else {
                console.log('status: ',     xhr.status)
                setStatus("ERROR");
            }
        };
        xhr.send(data);
    }

    return (
        <div class="contact-section">
            <h2 className="title is-3 has-text-white">Contact Me</h2>
            <br />
            <form
                id="contact-form"
                method="post"
                action="https://formspree.io/xwkrpozj"
                onSubmit={submitForm}
            >
                <div class="field">
                    <label class="label has-text-warning">Name</label>
                    <div class="control">
                        <input class="input" type="text" name="name" placeholder="e.g Alex Smith"  required />
                    </div>
                </div>
                <br />
                <div class="field">
                    <label class="label has-text-warning">Email</label>
                    <div class="control">
                        <input class="input" type="text" name="email" placeholder="e.g alexsmith@gmail.com" required />
                    </div>
                </div>
                <br />
                <div class="field">
                    <label class="label has-text-warning">Message</label>
                    <div class="control">
                        <textarea class="textarea" name="message" placeholder="Say hi!" required></textarea>
                    </div>
                </div>
                <br />
                {
                    status === "SUCCESS" ?
                        <h5 class="subtitle is-4 has-text-success">Thank you!</h5> :
                        <button class="button is-link is-fullwidth">Submit</button>
                }
                {
                    status === "ERROR" && (
                        <>
                            <br />
                            <h5 class="subtitle has-text-danger">Ooops! There was an error.</h5>
                        </>
                    )
                }
            </form>

        </div>
    )
}

export default ContactSection