import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = (props) => {
    const { icon, href, label } = props

    return (
        <a href={href} aria-label={label}>
            <span className="icon is-large fa-2x">
                <FontAwesomeIcon icon={icon} />
            </span>
        </a>
    )
}

export default Icon