import React from 'react'
import {
	faGithub, faTwitter, faLinkedin, faCodepen
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons'

import Icon from "../../atoms/Icon"

const ProfileIcons = () => {
	return (
		<div className="profile-icons">
			<Icon label="github" icon={faGithub} href="https://github.com/dineshalapati" />
			<Icon label="codepen" icon={faCodepen} href="https://codepen.io/DineshAlapati" />
			<Icon label="twitter" icon={faTwitter} href="https://twitter.com/dineshalapati" />
			<Icon label="linkedin" icon={faLinkedin} href="https://www.linkedin.com/in/alapatidinesh" />
			<Icon label="email" icon={faEnvelopeOpen} href="mailto:dine.alapati@gmail.com" />
		</div>
	)
}

export default ProfileIcons
