import { useStaticQuery, graphql } from "gatsby"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Query = () => {
  const data = useStaticQuery(graphql`
	query portfolioImageQuery {
		site {
			siteMetadata {
			  title
			}
		}
		stroomtrooperIcon: file(relativePath:{ eq: "stormtrooper-50-icon.png" }) {
			childImageSharp {
				fixed(width: 40, height: 40) {
					...GatsbyImageSharpFixed
				}
			}
		}
		profileImage: file(relativePath:{ eq: "dinesh.jpg" }) {
			childImageSharp {
				fixed(width: 200, height: 200) {
					...GatsbyImageSharpFixed
				}
			}
		}
	}
`)

  return data
}

export default Query
