import React from 'react'

const Section = ({ id, alignment, children }) => {
	return (
		<div id={id} className={`page-view-section ${alignment}`}>
			{children}
		</div>
	)
}

export default Section