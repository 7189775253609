import React from 'react'
import Img from 'gatsby-image';

import Emoji from '../../atoms/Emoji'
import ProfileIcons from '../ProfileIcons'

const HomeSection = ({ data }) => {
	return (
		<div className="home-section has-text-centered">
			<figure className="is-128x128">
				<Img
					fixed={data.profileImage.childImageSharp.fixed}
					imgStyle={{ borderRadius: '100%' }}
					alt="Dinesh Alapati"
				/>
			</figure>
			<div>
				<h1 className="title is-2 is-spaced has-text-white">
					Hey! <Emoji symbol="👋" label="wave" /> I'm Dinesh Alapati
				</h1>
				<br/>
				<h2 className="title is-4 is-spaced has-text-warning">
					I write code for living!
				</h2>
			</div>
			<ProfileIcons />
		</div>
	)
}

export default HomeSection