import React from 'react'
import PropTypes from 'prop-types'

const Emoji = props => {
  const { symbol, label } = props
  return (
    <>
      {' '}
      <span
        className="emoji"
        role="img"
        aria-label={label ? label : ""}
        aria-hidden={label ? "false" : "true"}
      >
        {symbol}
      </span>
      {' '}
    </>
  )
}

Emoji.propTypes = {
  symbol: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

export default Emoji