import React from 'react'

const AboutMeSection = () => {
	return (
		<div>
			<section className="about-me-section section content is-size-4-desktop is-size-5-touch">
				<h2 className="title is-3 has-text-white">About Me</h2>
				<br />
				<div>
					<p className="has-text-warning">
						{
							`I'm a Full Stack Software Engineer.`
						}
					</p>
					<p>
						{
							`I have experience in
							creating SPAs, Command-Line Tools, Microservices and
							CI/CD Pipelines.`
						}
					</p>
					<p>
						{
							`Currently interested in SEO, Software Architecture and Cloud Platforms.`
						}
					</p>
					<p>
						{
							`If you're interested in working together, let's get in touch.`
						}
					</p>
					{/* <p>
						Check out my <Link to="/projects">projects</Link>.
					</p> */}
				</div>
			</section>
		</div>
	)
}

export default AboutMeSection